.new-new-barrr {
    padding-left: 30px;
    padding-right: 30px;
}

.main-navbar {
    // position: absolute;
    width: 100%;
    border-bottom: 1px solid #F2F2F2;
    background: #FFF;

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0px;
        margin: 0px;

        .genratedtext {
            color: #2D3436;
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.44px;
        }

        .newnav-logoutbtn {
            display: flex;
            padding: 13px 35px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 5px;
            border: 1px solid #FF0083;
            border-radius: 5px;
            background: #fff;
            color: #FF0083;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
        }

        .pinkbtn {
            display: flex;
            padding: 13px 35px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 5px;
            border: 1px solid #FF0083;
            border-radius: 5px;
            background: #FF0083;
            color: white;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
        }
    }
}

.modal {
    .modal-dialog {
        max-width: 652px;

        .modal-content {
            border-radius: 12px;
            border: 1px solid #D1D1D1;
            background: #FFF;
            backdrop-filter: blur(7.5px);
            padding: 10px;

            .modal-header {
                padding: 12px 10px;
                border-radius: 8px;
                border: 1px solid #EDEDED;

                .modal-title {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                }

                .btn-close {
                    // background: url(../../Assests/close-circle.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    padding: 0px;
                    margin: 0px;
                    width: 26px;
                    height: 26px;
                    box-shadow: none !important;
                    outline: none !important;
                    opacity: unset;
                }
            }

            .modal-body {
                padding-top: 20px !important;
                padding: 0px;

                .modalcardmain {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 15px;

                    .innercard {
                        display: flex;
                        height: 209px;
                        padding: 10px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        border: 1px solid #EDEDED;
                        width: 50%;
                        cursor: pointer;

                        &:hover {
                            background: #FFF;
                            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.10);
                            transition: 0.7s;

                            .modaltext {
                                color: #000;
                            }
                        }

                        .modaltext {
                            color: #B3B3B3;
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:992px) {
    .main-navbar {
        // background: linear-gradient(0deg, #FAFAFA 0%, #FAFAFA 100%), #FFF;
        border: none !important;
    }

    .main-navbar .navbar {
        gap: 30px;

        .logomain {
            width: 100.53px;
            height: 26.914px;
        }
    }

    .main-navbar .navbar .pinkbtn {
        padding: 9px 15px;
    }
}

@media (max-width:600px) {
    .modal .modal-dialog .modal-content .modal-body .modalcardmain {
        flex-direction: column;
    }

    .modal .modal-dialog .modal-content .modal-body .modalcardmain .innercard {
        width: 100%;
        height: 153px;
    }

    .main-navbar {
        position: static;
        // background: none;
        background: linear-gradient(0deg, #FAFAFA 0%, #FAFAFA 100%), #FFF;

        .navbar {
            .genratedtext {
                text-align: left;
                width: 100%;
            }
        }
    }
}