.dashboard-wrapper-main {
    .dashboard-wrapper-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;

        h3 {
            color: #2D3436;
            font-family: Ubuntu;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.44px;
        }

        button {
            border-radius: 5px;
            background: #FF0083;
            color: #FFF;
            // font-family: Ubuntu;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            display: flex;
            padding: 13px 45px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border: none;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.leader-page-wrapper {
    .datatable {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 25px;
    }

    .backkkk-btnn {
        .back-link {
            width: 124px;
            display: block;
        }

        button {
            border-radius: 8px;
            border: 1px solid #F2F2F2;
            background: #FFF;
            display: flex;
            padding: 13px 30px;
            // flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-bottom: 20px;
        }
    }

    .leader-home-tab-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
        // padding-bottom: 20px;
        border-radius: 10px;
        border: 1px solid #F2F2F2;
        background: #FFF;
        margin-bottom: 20px;

        .leader-home-item-main {
            display: flex;
            padding: 25px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 10px;
            height: 160px;
            width: 100%;
            justify-content: space-between;

            .leader-home-item {
                p {
                    color: #181818;
                    // font-family: Ubuntu;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    opacity: 0.6000000238418579;
                    margin-bottom: 5px;
                }

                h4 {
                    color: #131313;
                    // font-family: Ubuntu;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                }
            }
        }
    }
}

.datatable.leader-home-datetable-tableeee {
    padding-top: 10px;
}

.datatable {
    // padding-top: 115px;
    padding-top: 60px;
    padding-bottom: 30px;

    .upperdata.forresponsivemain.dashboard-box-full {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;

        .color1 {
            background: #D2D4FF;
        }

        .color2 {
            background: #F8D7E9;
        }

        .color3 {
            background: #FCEFB4;
        }

        .color4 {
            background: #E1ECE4;
        }

        .color5 {
            background: #F7F3E3;
        }

        .color6 {
            background: #D1FFFE;
        }

        .forresponsiveinner {
            display: flex;
            padding: 10px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 10px;
            height: 165px;
            width: 100%;

            .dataimg {
                border-radius: 140px;
                background: rgba(0, 11, 255, 0.05);
                width: 40px;
                height: 40px;
                padding: 10px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .datainfomain {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                margin-top: 10px;

                .datainfo {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;

                    .datainfotext {
                        color: #474747;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        letter-spacing: -0.14px;
                    }

                    .datainfocolor {
                        border-radius: 4px;
                        background: #627EEA;
                        width: 3px;
                        height: 14px;
                    }

                    .datainfocolortwo {
                        border-radius: 4px;
                        background: #26A17B;
                        width: 3px;
                        height: 14px;
                    }
                }
            }

            .datapara {
                color: #181818;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                opacity: 0.6000000238418579;
                padding-top: 15px;
                padding-bottom: 10px;
            }

            .datahead {
                color: #131313;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.22px;
            }
        }
    }

    .upperdata.forresponsivemain.dashboard-box-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;

        .color1 {
            background: #D2D4FF;
        }

        .color2 {
            background: #F8D7E9;
        }

        .color3 {
            background: #FCEFB4;
        }

        .color4 {
            background: #E1ECE4;
        }

        .color5 {
            background: #F7F3E3;
        }

        .color6 {
            background: #D1FFFE;
        }

        .forresponsiveinner {
            display: flex;
            padding: 10px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 10px;
            height: 165px;
            width: 100%;

            .dataimg {
                border-radius: 140px;
                background: rgba(0, 11, 255, 0.05);
                width: 40px;
                height: 40px;
                padding: 10px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .datainfomain {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                margin-top: 10px;

                .datainfo {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;

                    .datainfotext {
                        color: #474747;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        letter-spacing: -0.14px;
                    }

                    .datainfocolor {
                        border-radius: 4px;
                        background: #627EEA;
                        width: 3px;
                        height: 14px;
                    }

                    .datainfocolortwo {
                        border-radius: 4px;
                        background: #26A17B;
                        width: 3px;
                        height: 14px;
                    }
                }
            }

            .datapara {
                color: #181818;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                opacity: 0.6000000238418579;
                padding-top: 15px;
                padding-bottom: 10px;
            }

            .datahead {
                color: #131313;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.22px;
            }
        }
    }

    .table-responsivecolorbg {
        border-radius: 5px;
        background: #FFF;
        border: 1px solid #F2F2F2;
    }

    table {
        margin: 0px;
        padding: 0px;
    }

    th {
        color: #2D3436;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.28px;
        vertical-align: middle !important;
        border: none;
        position: relative;
        white-space: nowrap;
        padding: 28px 20px;
    }

    td {
        color: #2D3436;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.28px;
        font-weight: 400;
        vertical-align: middle !important;
        border-top: 1px solid #F2F2F2;
        background: #FFF;
        padding: 0;
        padding: 30px 20px;
        white-space: nowrap;

        .forflex {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .forflexpara {
                color: #2D3436;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.28px;
                font-weight: 400;
            }
        }

        .dark {
            font-weight: 700;
        }
    }

    .textblack {
        display: flex;
        align-items: center;
        gap: 12px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 25px;
        border-radius: 0px 0px 5px 5px;
        border-right: 1px solid #F2F2F2;
        border-bottom: 1px solid #F2F2F2;
        border-left: 1px solid #F2F2F2;
        background: #FFF;

        .left-f {
            h6 {
                color: #A0A1A1;
                font-size: 14px;
                font-weight: 500;
                line-height: 100%;
            }
        }

        .right-f {
            .page-link {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
                border-radius: 5px;

                &:focus {
                    box-shadow: none !important;
                }

                &.active {
                    background: #FF0083;
                    color: #fff;
                }
            }

            .page-item:first-child .page-link {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
            }
        }
    }
}

.filter-btn {
    display: flex;
    flex-direction: column;
    width: 11px;
    margin-left: 10px;
}

.parent-tag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media (max-width:1300px) {
    .theme-container .upperdata.forresponsivemain.dashboard-box-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;
    }
}

@media (max-width:857px) {
    .leader-page-wrapper .datatable {
        padding-left: 15px;
        padding-right: 15px;
        // padding-top: 150px;
    }

    .datatable .upperdata {
        gap: 10px;
    }

    .datatable .upperdata .datacard {
        padding: 20px;
    }

    .leader-page-wrapper .leader-home-tab-box {
        flex-wrap: wrap;
        gap: 10px;

        .leader-home-item-main {
            width: 48.50% !important;
            padding: 20px;
            // height: 165px;

            .leader-home-item {
                h4 {
                    // color: #131313;
                    font-size: 14px;
                    // font-style: normal;
                    // font-weight: 500;
                    // line-height: 120%;
                }
            }
        }
    }

}

@media(max-width:768px) {
    .datatable .forresponsivemain {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width:600px) {

    .theme-container .upperdata.forresponsivemain.dashboard-box-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;
    }

    .dashboard-wrapper-main .dashboard-wrapper-header {
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        gap: 30px;
    }

    .dashboard-wrapper-main .dashboard-wrapper-header button {
        width: 100%;
    }

    // .datatable {
    //     padding-top: 98px;
    // }

    .maindashboard .content-side {
        padding-top: 80px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .leader-page-wrapper .datatable {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
    }

    .datatable .upperdata .datacard .dataimg {
        width: 30px;
        height: 30px;
        padding: 7px;

        .datainnerimg {
            width: 16px;
            height: 16px;
        }
    }

    .leader-page-wrapper .leader-home-tab-box {
        flex-wrap: wrap;
        gap: 10px;
        padding-bottom: 15px !important;

        .leader-home-item-main {
            width: 48.50%;
            padding: 15px;
            height: 70px !important;
            flex-direction: row !important;

            .mobile-wallet-ad {
                margin-top: 15px;
            }

            .leader-home-item {
                p {
                    font-size: 14px;
                }

                h4 {
                    // color: #131313;
                    font-size: 14px;
                    // font-style: normal;
                    // font-weight: 500;
                    // line-height: 120%;

                    white-space: nowrap;
                    width: 130px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .datatable .forresponsivemain .forresponsiveinner .datahead {
        font-size: 18px;
    }

    .datatable .forresponsivemain .forresponsiveinner .datainfomain {
        gap: 10px;
    }

    .datatable .forresponsivemain .forresponsiveinner .datapara {
        font-size: 12px !important;
    }

    .datatable .footer-content .left-f h6 {
        display: none;
    }

    .accmblview {
        display: block !important;

        .accmblviewhead {
            color: #2D3436;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.28px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            padding: 25px 28px;

            .arrowsacc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .accordion {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: none !important;

            .accordion-button::after {
                background: url("../../Assests/accarrowdown.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 8.571px;
                height: 5.143px;
            }

            .accordion-item:first-of-type .accordion-button {
                border-bottom-right-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
            }

            .accordion-item:last-of-type .accordion-button.collapsed {
                border-bottom-right-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
            }

            .accordion-item {
                border: none !important;
                background: transparent !important;
                box-shadow: none !important;
                outline: none !important;
                border-radius: none !important;

                .accordion-header {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;

                    .header-left-box {
                        margin-right: 27px;
                    }

                    .header-inner-top {
                        color: var(--yoda-admin-template-blacks-b-100, #2D3436);
                        font-size: 14px;
                        // font-family: Ubuntu;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        letter-spacing: 0.28px;
                        margin-bottom: 5px;
                    }

                    .header-inner-bottom {
                        color: #2D3436;
                        font-size: 14px;
                        font-family: Ubuntu;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0.28px;
                    }

                    .accordion-button:focus {
                        background: #F6F4F6;
                        border-color: #F2F2F2;
                    }

                    .accordion-button {
                        border-top: 1px solid #F2F2F2;
                        border-bottom: 1px solid #F2F2F2;
                        background: #FFF;
                        padding: 22px 25px;
                        border-radius: none !important;

                        &:focus {
                            box-shadow: none;
                        }

                        .accheadermain {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 12px;

                            .accmainimage {
                                width: 30px;
                                height: 30px;
                                border-radius: 60px;
                                background: rgba(0, 0, 0, 0.04);
                                object-fit: cover;
                                object-position: center;
                                display: flex;
                                align-items: center;

                                .accinnerimg {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 60px;
                                    background: rgba(0, 0, 0, 0.04);
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }

                            .acctext {
                                color: #2D3436;
                                font-size: 14px;
                            }
                        }
                    }
                }

                .accordion-body {
                    border: none !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;
                    padding: 20px 25px;
                    background: #FBFAFA;

                    .acctexts {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 24px;

                        .dark {
                            font-weight: 700;
                        }

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .textleft {
                            font-size: 14px;
                            color: #2D3436;
                            font-weight: 500;
                            line-height: 100%;
                            letter-spacing: 0.28px;
                        }

                        .textright {
                            color: #2D3436;
                            font-size: 14px;
                            line-height: 21px;
                            letter-spacing: 0.28px;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            gap: 5px;
                        }
                    }
                }
            }
        }
    }

    .datatable .table-responsivecolorbg {
        .table-responsive {
            display: none;
        }
    }

    .datatable {
        td {
            text-align: center;
        }

        th {
            text-align: center;
        }
    }

    .datatable .upper-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .right {
            width: 100%;

            .option-field {
                width: 100%;
            }
        }
    }

    .datatable .footer-content {
        flex-direction: column-reverse;
        justify-content: center;
        border-top: 1px solid #F2F2F2;
        align-items: center;
    }
}


@media (max-width:425px) {
    .datatable .forresponsivemain .forresponsiveinner .datapara {
        font-size: 12px;
    }

    .datatable .forresponsivemain .forresponsiveinner .datainfomain .datainfo .datainfotext {
        font-size: 11px;
    }
}