.main-navbar {
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        margin: 0px;

        .genratedtext {
            color: #2D3436;
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.44px;
        }

        .pinkbtn {
            display: flex;
            padding: 13px 45px;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background: #FF0083;
            color: #FFF;
            font-size: 16px;
            line-height: 100%;
            border: none;
        }
    }
}

.modal-backdrop {
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(5px);
    opacity: unset !important;
}

.modal {
    .modal-dialog {
        max-width: 524px;

        .modal-content {
            border-radius: 5px;
            border: 1px solid #EAEAEA;
            background: #FFF;
            box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
            padding: 30px;

            .modal-header {
                padding: 12px 10px;
                border-radius: 8px;
                border: 1px solid #E6E6E6;

                .modal-title {
                    color: #151515;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 110%;
                }

                .btn-close {
                    background: url('../../Assests/closeicon.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    width: 26px;
                    height: 26px;
                    padding: 0px;
                    margin: 0px;
                    opacity: unset;
                    box-shadow: none !important;
                    outline: none !important;
                }
            }

            .modal-body {
                padding: 40px 0px 0px;

                .modalpara {
                    color: #2D3436;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 100%;
                    margin-bottom: 15px;
                }

                .copypara {
                    color: #FF0083;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 110%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 40px;
                }

                .modalparamain {
                    color: #000;
                    text-align: center;
                    font-size: 22px;
                    padding-bottom: 40px;
                    font-weight: 500;
                    line-height: 110%;
                }

                .maindateinput {
                    position: relative;
                    margin-bottom: 20px;
                    width: 100%;

                    .dateinput {
                        border-radius: 5px;
                        border: 1px solid #FFE3F2;
                        background: #FFF;
                        padding: 19px 20px;
                        color: #000;
                        font-size: 16px;
                        line-height: 100%;
                        width: 100%;
                        padding-right: 50px;

                        &::placeholder {
                            color: #B8B8B8;
                            font-size: 16px;
                            line-height: 100%;
                        }
                    }

                    .dateimg {
                        position: absolute;
                        right: 20px;
                        top: 17px;
                    }
                }

                .modalbtns {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    .hoverbtn {
                        border-radius: 8px;
                        border: 1px solid #E6E6E6;
                        background: #FFF;
                        display: flex;
                        padding: 14px 0px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        color: #FF0083;
                        font-size: 16px;

                        &:hover {
                            background: #FF0083;
                            color: #FFF;
                            border: 1px solid transparent;
                            transition: 0.7s;
                        }
                    }

                    .unhoverbtn {
                        border-radius: 8px;
                        border: 1px solid transparent;
                        display: flex;
                        padding: 14px 0px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: 16px;
                        background: #FF0083;
                        color: #FFF;

                        &:hover {
                            border: 1px solid #E6E6E6;
                            color: #FF0083;
                            background: #FFF;
                            transition: 0.7s;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:992px) {
    .main-navbar .navbar .pinkbtn{
        width: 100%;
    }
    .main-navbar .navbar{
        margin-bottom: 25px;
        gap: 30px;
    }
    .modal .modal-dialog .modal-content .modal-body .modalparamain{
        font-size: 16px;
        padding-bottom: 30px;
    }
    .modal .modal-dialog .modal-content .modal-body{
        padding: 30px 0px 0px;
    }
    .modal .modal-dialog .modal-content .modal-body .copypara{
        margin-bottom: 30px;
    }
}