.maindashboard {
    padding: 0px 0;
    min-height: 100vh;

    .lowerdashboardmain {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
    }

    .sidebar {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        background: #FCFCFC;
        width: 244px;
        max-width: 100%;
        min-height: 100vh;
        max-height: 100%;
        height: 100%;
        display: block;
        position: relative;

        .logoutbtn {
            display: flex;
            width: 205px;
            padding: 14px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #FE0000;
            background: #FAFCFF;
            color: #FE0000;
            font-size: 14px;
            font-weight: 500;
            line-height: 100%;
            position: absolute;
            top: unset;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .sidebarheader {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 22px 0px 25px;
            justify-content: center;

            .logoside {
                width: 127px;
                height: 34px;
            }
        }

        .heading {
            font-style: normal;
            font-family: "Gsemibold";
            font-size: 22px;
            line-height: 26px;
            color: #777e91;
            padding: 30px 25px;
        }

        .nav-item {
            width: 100%;
        }

        .settings {
            border-top: 1px solid #EFECEC !important;
            border-bottom: 1px solid #EFECEC !important;
            padding: 30px 22px !important;
            margin-top: 15px;
        }

        .nav-link {
            color: #063540;
            font-size: 14px;
            font-weight: 500;
            line-height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 15px 20px;
            width: 100%;
            gap: 10px;
            border-radius: 0px;
            border: none;

            .comingssontext {
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 100%;
                text-align: center;
                text-transform: uppercase;
                color: #4C9540;
            }
        }

        .nav-link.active,
        .show>.nav-link {
            background: rgba(255, 0, 131, 0.05);
        }

        .pink.active,
        .show>.pink {
            background: #FFEDF7 !important;
            border-left: 3px solid #FF0083 !important;
            color: #FF0083 !important;
        }
    }

    .content-side {
        background: transparent;
        padding: 0px 0px 30px;
        position: relative;
        width: calc(100% - 284px);

        .tab-pane {
            padding-top: 0px;
        }
    }
}

@media (max-width:1199px) {
    .maindashboard1 {
        .profile {
            margin-left: 20px;
        }

        .share-btn {
            right: 20px !important;
        }
    }

    .maindashboard .content-side .fifth .headingfifth h6::before {
        width: 200px;
        left: -241px;
    }

    .maindashboard .content-side .fifth .headingfifth h6::after {
        width: 200px;
        right: -241px;
    }
}

.sidebar-icon {
    display: none;
}

@media (max-width: 991px) {
    .maindashboard .sidebar .logoutbtn {
        width: 90%;
    }

    .maindashboard .sidebar .sidebarheader {
        display: none !important;
    }

    .maindashboard .content-side .tab-pane {
        padding-top: 0px;
    }

    .maindashboard .content-side {
        // padding-top: 116px;
        // padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .maindashboard .accesscode-content-side {
        padding-top: 116px;
    }

    .maindashboard .sidebar {
        width: 100%;
        background: #FFFFFF;
        border-radius: 0px;
    }

    .maindashboard .content-side {
        width: 100%;
    }

    .maindashboard .sidebar .nav-link {
        flex-direction: row;
        justify-content: flex-start;
    }

    .maindashboard .sidebar .logoside {
        display: none;
    }

    .sidebar-icon {
        display: block !important;
        background: linear-gradient(0deg, #FAFAFA, #FAFAFA), #FFFFFF;
        padding: 17px 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mobilelogo {
            display: block !important;
            height: 24px;
            width: 90px;
        }

        .sidebarheader {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            align-content: flex-start;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 9px;

            .sidebarhead {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.02em;
                color: #131313;
            }
        }

        .lefticons {
            display: flex;
            align-items: center;
            gap: 15px;
            position: absolute;
            top: 17px;
            right: 20px;
            z-index: 99;

            .notificationimg {
                position: relative;

                .mainnotificationdot {
                    position: absolute;
                    top: 0px;
                    right: 1px;
                }
            }
        }
    }

    .maindashboard .content-side .mainbtns {
        display: none;
    }

    .btn-success {
        display: block !important;
    }

    .maindashboard .close-btn {
        display: block !important;
    }

    .mobielSidebarHide {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -100%;
        z-index: 99;
        width: 100%;
        animation-name: moveInleft;
        animation-duration: 2s;
    }

    @keyframes moveInleft {
        0% {
            opacity: 1;
            transform: translateX(300px);
        }

        100% {
            opacity: 0;
            transform: translateX(-100px);
        }
    }

    .mobielSidebarShow {
        position: fixed;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
        background: #FAFAFA;

        &::-webkit-scrollbar {
            display: none !important;
        }

        left: 0%;
        z-index: 9999999;
        width: 100%;
        animation-name: moveInRight;
        animation-duration: 1s;
        padding: 0;
        height: 100%;

        .sidebarheader {
            display: flex !important;
            background: linear-gradient(0deg, #FAFAFA, #FAFAFA), #FFFFFF;
            padding: 17px 20px;

            .sidebarheaderss {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                align-content: flex-start;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 9px;

                .sidebarhead {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.02em;
                    color: #131313;
                }
            }

            .sidebarheaderlogo {
                height: 24px;
                width: 90px;
            }

            .lefticons {
                display: flex;
                align-items: center;
                gap: 15px;
                position: absolute;
                top: 17px;
                right: 20px;
                z-index: 99;

                .notificationimg {
                    position: relative;

                    .mainnotificationdot {
                        position: absolute;
                        top: 0px;
                        right: 1px;
                    }
                }
            }
        }
    }

    @keyframes moveInRight {
        0% {
            opacity: 0;
            transform: translateX(-100px);
        }

        100% {
            opacity: 1;
            transform: translate(0);
        }
    }

    .mobile-side-none {
        display: none;
    }

    .padd-md {
        padding: 0;
    }

    .maindashboard .back-btn {
        padding: 0 15px;
    }

    .maindashboard .top-heading {
        padding: 0 15px;
    }

    .mainprofile-right {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .sidebar-icon {
        // position: absolute;
        top: 0;
        right: 0px;
        width: 100%;
        left: 0px;
    }

    .maindashboard .top-heading {
        margin-top: 50px;
    }

    .maindashboard .lowerdashboardmain {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .padd-md {
        padding: 0;
    }

    .maindashboard .content-side .first .cover .add-cover {
        bottom: unset;
        top: 20px;
    }

    .maindashboard .content-side .first .bottom-content .badges .btns-two .edit-btn {
        width: 75%;
    }

    .maindashboard .content-side .first .bottom-content .badges .btns-two .refresh-btn {
        width: 22%;
    }

    .maindashboard .bottom-btn {
        flex-direction: column;
    }

    .maindashboard .bottom-btn button {
        width: 100%;
    }

    .maindashboard .tile p {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .maindashboard .content-side .fifth .headingfifth h6::before {
        display: none;
    }

    .maindashboard .content-side .fifth .headingfifth h6::after {
        display: none;
    }

    .maindashboard .content-side .fifth .bottom-content .inner-card {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }

    .maindashboard .content-side .fifth .bottom-content .inner-card .left {
        text-align: center;
        order: 2;
        margin-top: 20px;
    }

    .maindashboard .content-side .fifth .bottom-content .last-inline-text p {
        font-size: 14px;
    }

    .maindashboard .content-side .six .main-content h6 {
        text-align: center;
    }

    .maindashboard .content-side .six .main-content h6 {
        font-size: 20px;
    }

    .maindashboard .content-side .six .main-content p {
        font-size: 16px;
    }

    .maindashboard {
        padding: 0 !important;
    }

    .maindashboard .sidebar {
        height: 100%;
        padding-top: 42px;
    }

    .maindashboard .mainprofile-right .bottom-card .top-parent {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px 0;

    }
}


.ytctctcctctcc {

    display: none !important;

}