.dost-buttons-wrapper {
    .dropdown-toggle {
        background: none;
        display: flex;
        width: 35px;
        height: 35px;
        padding: 5.5px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        &::after {
            display: none;
        }

        &:active {
            border-radius: 140px;
            border: 1px solid #F8F8F8 !important;
        }

        &:focus {
            border-radius: 140px;
            border: 1px solid #F8F8F8 !important;
        }
    }

    .dropdown-menu {
        border-radius: 8px;
        border: 1px solid #EAEAEA;
        background: #FFF;
        box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.05);
        padding: 0px;

        li {
            padding: 0px;

            &:nth-child(1) a {
                border-top-left-radius: 7.3px;
                border-top-right-radius: 7.3px;
            }

            &:nth-child(2) a {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }

            &:nth-child(3) a {
                border-bottom-left-radius: 7.3px;
                border-bottom-right-radius: 7.3px;
            }

            a {
                padding: 14px 15px;
                color: #2D3436;
                font-size: 14px;
                font-family: Ubuntu;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;

                img {
                    margin-right: 10px;
                }

                &:hover {
                    border-color: #F3F3F3;
                    background: #FFF4FA;
                }
            }
        }
    }
}

.datatable {
    .table-responsivecolorbg {
        border-radius: 7px 7px 0px 0px;
        background: #fff;
    }

    table {
        margin: 0px;
        padding: 0px;
    }

    th {
        color: #2d3436;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.28px;
        vertical-align: middle !important;
        border: none;
        position: relative;
        white-space: nowrap;
        padding: 28px 20px;
        cursor: pointer;


        color: #2D3436;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.28px;
    }

    td {
        color: #2d3436;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.28px;
        font-weight: 400;
        vertical-align: middle !important;
        border-top: 1px solid #f2f2f2;
        background: #fff;
        padding: 0;
        padding: 30px 20px;
        white-space: nowrap;

        .dark {
            font-weight: 700;
        }

        .dark.name-itemmm .normal-weightttt {
            font-weight: 400;
        }
    }

    .wallet-address-td {
        p {
            white-space: nowrap;
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .textblack {
        display: flex;
        align-items: center;
        gap: 12px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 25px;
        border-radius: 0px 0px 5px 5px;
        border-right: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
        background: #fff;

        .left-f {
            h6 {
                color: #a0a1a1;
                font-size: 14px;
                font-weight: 500;
                line-height: 100%;
            }
        }

        .right-f {
            .page-link {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #bfc3c7;
                border-radius: 5px;

                &:focus {
                    box-shadow: none !important;
                }

                &.active {
                    background: #ff0083;
                    color: #fff;
                }
            }

            .page-item:first-child .page-link {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #bfc3c7;
            }
        }
    }
}

.filter-btn {
    display: flex;
    flex-direction: column;
    width: 11px;
    margin-left: 10px;
}

.parent-tag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

// .sdkfnklasf {
//     padding-left: 15px;
// }

@media (max-width: 600px) {
    .datatable .footer-content .left-f h6 {
        display: none;
    }

    .hash-img-mobile {
        margin-left: 10px;
    }

    .accmblview {
        display: block !important;

        .accmblviewhead {
            color: #2d3436;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.28px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            padding: 25px 28px;

            .arrowsacc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .accordion {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: none !important;

            .accordion-button::after {
                background: url("../../Assests/accarrowdown.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 8.571px;
                height: 5.143px;
            }

            .accordion-item {
                border: none !important;
                background: transparent !important;
                box-shadow: none !important;
                outline: none !important;
                border-radius: none !important;

                .accordion-header {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;

                    .accordion-button {
                        border-top: 1px solid #f2f2f2;
                        border-bottom: 1px solid #f2f2f2;
                        background: #fff;
                        padding: 22px 25px;
                        justify-content: space-between;

                        &:focus {
                            box-shadow: none;
                        }

                        &::after {
                            position: absolute;
                            right: 8px;
                        }

                        &[aria-expanded="true"] {
                            color: #2D3436;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700 !important;
                            line-height: 21px;
                            letter-spacing: 0.28px;
                        }

                        .accheadermain {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 12px;

                            .accmainimage {
                                width: 30px;
                                height: 30px;
                                border-radius: 60px;
                                background: rgba(0, 0, 0, 0.04);
                                object-fit: cover;
                                object-position: center;
                                display: flex;
                                align-items: center;

                                .accinnerimg {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 60px;
                                    background: rgba(0, 0, 0, 0.04);
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }

                            .acctext {
                                color: #2d3436;
                                font-size: 14px;
                            }
                        }
                    }
                }

                .accordion-body {
                    border: none !important;
                    // background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;
                    padding: 20px 25px;

                    .acctexts {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 40px;

                        .dark {
                            font-weight: 700;
                        }

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .textleft {
                            font-size: 14px;
                            color: #2d3436;
                            font-weight: 500;
                            line-height: 100%;
                            letter-spacing: 0.28px;
                        }

                        .textright {
                            color: #2d3436;
                            font-size: 14px;
                            line-height: 21px;
                            letter-spacing: 0.28px;
                        }
                    }
                }
            }
        }
    }

    .datatable .table-responsivecolorbg {
        .table-responsive {
            display: none;
        }
    }

    .datatable {
        td {
            text-align: center;
        }

        th {
            text-align: center;
        }
    }

    .datatable .upper-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .right {
            width: 100%;

            .option-field {
                width: 100%;
            }
        }
    }

    .datatable .footer-content {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}